import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout/fr"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import WeeklyMenu from "../components/restaurant-menu/weekly-menu"

const MenuPage = (props) => {
	const seoDescription = "Pour fêter les 50 ans de notre établissement, du mardi au vendredi nous vous proposons une carte où vous pourrez déguster des plats de saison qui nous ont accompagnés tout au long de ces années.";
	const entrants = "Entrées";
	const platsPrincipals = "Plats principaux";
	const postres = "Dessert";
	const ivaInclos = "TVA incluse";
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Menu de la semaine"
	    	lang="fr"
	    	description={seoDescription}
	    />
	    <HeroImage 
	      titleText="Menu de la semaine"
	      image="carta" 
	    />
	    <WeeklyMenu 
	    	lang='fr'
	    	entrants={entrants}
	    	principals={platsPrincipals}
	    	postres={postres}
	    	iva={ivaInclos}
	    />
	    <Link to="/fr/carta">
	    	<h2>Voir le Menu</h2>
	    </Link>
	  </Layout>
	)
}

const MenuSection = styled.div`
	margin: 140px 70px;
	display: flex;
	@media (max-width: 540px){
		margin: 100px 5px;
	  flex-direction: column;
	}
`

const SelectBox = styled.select`
	font-size: 16px;
	@media (min-width: 540px){
	  display: none;
	}
`

const MenuHeaders = styled.div`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	width: 250px;
	li {
	  cursor: pointer;
	  padding: 10px;
	  line-height: 20px;
	}
	@media (max-width: 540px){
	  display: none;
	}
`

export default MenuPage